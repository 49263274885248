let userList = {}
async function waitlistSubmit(submitBtn){
    let email = document.getElementById("waitlist_email").value
    if(email && userList[email]){
        return alert("You are added to the waitlist! We'll get in touch soon.")
    }
    if(email){
        let loadingSpin = document.getElementById("loading_spin")
        loadingSpin.style.display = 'inline-block';
        submitBtn.disabled = true
        try{
            let response = await fetch('https://qwoach.com/api/public/waitinglist', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email})
            })
            if(!response.ok){
                throw new Error("hmm")
            }
            userList[email] = true
            alert("You are added to the waitlist! We'll get in touch soon.")
        } catch (error){
            alert("Can't add you to the waitlist. Please double check your email or contact support.")
        }
        submitBtn.disabled = false
        loadingSpin.style.display = 'none';

    } else {
        alert("Please provide your email")
    }
}

(function (){
    let submitBtn = document.getElementById("waitlist_submit")
    if(submitBtn){
        submitBtn.addEventListener("click", () => waitlistSubmit(submitBtn))
    }
})()
