let Cookies = require('js-cookie')

function addIframe(src){
    let frame = document.createElement("iframe");
    frame.setAttribute("src", src);
    frame.style.width = "0";
    frame.style.height = "0";
    document.body.appendChild(frame)

}
let url = new URL(location.href)

let specialId = url.searchParams.get("specialId")
let affiliateId = specialId || url.searchParams.get("affiliateId") || url.searchParams.get("link") || url.searchParams.get("refId")

let link = 2907

if(specialId){
    link = 3273
}
if(affiliateId){
    Cookies.set("avndrid", affiliateId, {expires: 30})
    // let url = `https://a.paddle.com/v2/click/101378/${affiliateId}?link=${link}`
    // addIframe(url)
}
