
/**
 * All the modal related methods.
 */

+function($){

  page.initModal = function() {
    var body = page.body;

    /**
     * Autoshow
     */
    $('.modal[data-autoshow]').each(function(){
      var modal = $(this),
          delay = parseInt( modal.dataAttr('autoshow') );
      setTimeout( function() { modal.modal('show') }, delay);
    });


     /**
      * Exit
      */
    $('.modal[data-exitshow]').each(function(){
      var modal  = $(this),
          delay  = parseInt( modal.dataAttr('delay', 0) ),
          target = modal.dataAttr('exitshow'),
          onShow = modal.dataAttr('on-show', null)

      console.log("onShow = ", onShow)

      if ( $(target).length ) {
        $(document).one('mouseleave', target, function() {
          setTimeout( function() {
            modal.modal('show')
            if ( onShow !== null ) {
              window[onShow]();
            }
          }, delay);
        });
      }

    });

  }

}(jQuery);
