
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/
require('./core/analyticsSetup')
require('./core/waitlistSubmit')
require('./core/paddleSetup')
require('./core/firebaseUserExists')
require('../affiliate')

